import { alpha } from "@mui/material/styles"
import { styled } from "@mui/system"

export const ENUM_BUTTON = {
  CANCEL: "cancel",
  CONFIRM: "confirm",
  DELETE: "delete",
  EDIT: "edit",
  NEW: "new",
  LINK: "link",
  CUSTOM: "custom"
}

export const ButtonConfig = {
  [ENUM_BUTTON.CANCEL]: {
    backgroundColor: "transparent",
    color: alpha("#434343", 1),
    borderColor: alpha("#434343", 1),
    "&:hover": {
      color: alpha("#434343", 0.7),
      borderColor: alpha("#434343", 0.7),
      backgroundColor: "#F6F6F6"
    }
  },
  [ENUM_BUTTON.CONFIRM]: {
    backgroundColor: "#434343",
    color: "white",
    border: "none",
    "&:hover": {
      backgroundColor: props =>
        props.backgroundColor
          ? alpha(props.backgroundColor, 0.6)
          : alpha("#434343", 0.6)
    }
  },
  [ENUM_BUTTON.DELETE]: {
    backgroundColor: "#E15241",
    color: "white",
    border: "none",
    "&:hover": {
      backgroundColor: alpha("#E15241", 0.6)
    }
  },
  [ENUM_BUTTON.EDIT]: {
    backgroundColor: "#434343",
    fontWeight: 400,
    border: "none",
    "&:hover": {
      backgroundColor: alpha("#434343", 0.6),
      border: "none",
      outline: 0
    }
  },
  [ENUM_BUTTON.NEW]: {
    backgroundColor: "#434343",
    color: "white",
    border: "none",
    "&:hover": {
      backgroundColor: alpha("#434343", 0.6),
      border: "none",
      outline: 0
    }
  },
  [ENUM_BUTTON.CUSTOM]: {
    color: "white",
    backgroundColor: "#434343",
    "&:hover": {
      backgroundColor: alpha("#434343", 0.6),
      border: "inherit"
    }
  }
}

export const LabelButton = styled("span")({
  whiteSpace: "nowrap",
  textTransform: "none",
  fontSize: "14px",
  lineHeight: "initial",
  fontWeight: 400,
  marginTop: 0.5
})
