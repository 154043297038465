"use client";

import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useResponsive } from "@src/hooks";
import ProductModel from "@src/models/product";
import FavoriteIcon from "./favorite-Icon";
import ImageCarouselDesktop from "./image-carousel-desktop";
import ImageCarouselMobile from "./image-carousel-mobile";
import InfoAndInventory from "./info-and-inventory";
import SizeModal from "./size-modal";
import BadgeProductType from "./badge-type";
import ProductSkeleton from "./product-skeleton";
import { useLayoutContext } from "@src/layouts/provider";
type Props = {
  isModal?: boolean;
  detail: ProductModel;
  fetchingId: boolean;
};
const ProductDetail = (props: Props) => {
  const {
    isModal,
    detail,
    fetchingId
  } = props;
  const {
    config
  } = useLayoutContext();
  const {
    isMobile,
    isDesktop
  } = useResponsive();
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [openSizeModal, setOpenSizeModal] = useState(false);
  const handleShowSizeModal = e => {
    e.preventDefault();
    setOpenSizeModal(true);
  };
  return <Box sx={{
    position: "relative",
    height: "100%",
    minHeight: {
      xs: "500px",
      lg: "660px"
    },
    minWidth: {
      xs: "auto",
      lg: "960px"
    }
  }} data-sentry-element="Box" data-sentry-component="ProductDetail" data-sentry-source-file="index.tsx">
      <ProductSkeleton loading={fetchingId} data-sentry-element="ProductSkeleton" data-sentry-source-file="index.tsx" />
      <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Stack mt={2} position="relative" flexDirection={{
        xs: "column",
        md: "row"
      }} gap={{
        xs: "16px",
        md: "32px"
      }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Box sx={{
          position: "absolute",
          top: {
            xs: 16,
            md: 4
          },
          left: {
            xs: 8,
            md: 4
          },
          zIndex: 99
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <BadgeProductType detail={detail} flash_sale={config?.flash_sale || {}} data-sentry-element="BadgeProductType" data-sentry-source-file="index.tsx" />
          </Box>
          {isMobile && <Box width="100%" height="auto">
              <ImageCarouselMobile isLoading={fetchingId} isModal={isModal} name={detail?.name} detail={detail} selectedImage={selectedImage} />
              {!isModal && <Stack display={{
            xs: "flex",
            md: "none"
          }} alignItems="center" mt={1} mb={-1}>
                  <Box sx={{
              cursor: "pointer",
              paddingRight: "12px"
            }}>
                    <FavoriteIcon productId={detail?.id} slug={detail?.slug} />
                  </Box>
                  <Typography variant="body1">
                    Thêm vào danh sách yêu thích
                  </Typography>
                </Stack>}
            </Box>}
          {isDesktop && <Box width="55%" maxWidth="55%">
              <ImageCarouselDesktop isModal={isModal} detail={detail} selectedImage={selectedImage} />
            </Box>}
          <Box width={{
          xs: "100%",
          md: "45%"
        }} maxWidth={{
          xs: "100%",
          md: "45%"
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <InfoAndInventory isMobile={isMobile} isModal={isModal} detail={detail} onSelectedImage={setSelectedImage} onOpenSizeModal={handleShowSizeModal} data-sentry-element="InfoAndInventory" data-sentry-source-file="index.tsx" />
          </Box>

        </Stack>

        <Stack display={{
        xs: "none",
        md: "flex"
      }} alignItems="center" mt={1.5} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Box sx={{
          cursor: "pointer",
          backgroundColor: "white",
          padding: "4px",
          marginRight: "12px",
          borderRadius: "50%"
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <FavoriteIcon productId={detail?.id} slug={detail?.slug} data-sentry-element="FavoriteIcon" data-sentry-source-file="index.tsx" />
          </Box>
          <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            Thêm vào danh sách yêu thích
          </Typography>
        </Stack>
      </Box>
      <SizeModal imgUrl={detail?.size_image || ""} open={openSizeModal} onClose={() => setOpenSizeModal(false)} data-sentry-element="SizeModal" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default ProductDetail;