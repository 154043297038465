"use client";

import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import useQuery from "@src/hooks/use-query";
import { useCurrentUser } from "@src/hooks/use-current-user";
type ConfigType = {
  footer_code: string;
  header_code: string;
  meta: string;
  customer_keywords: string[];
  total_cart_items: number;
  trending_keywords: string[];
  payment_methods: Array<any>;
  flash_sale: {
    id: number;
    from: string;
    to: string;
    isActive: boolean;
    isNotStarted: boolean;
    isGoingOn: boolean;
    color: string;
  };
  on_sale: boolean;
};
const defaultCtx = {
  config: null as ConfigType | null,
  user: {},
  refetch: () => {}
};
const LayoutContext = createContext(defaultCtx);
interface Props {
  children: any;
  rootConfig?: ConfigType;
}
export const LayoutProvider = ({
  children,
  rootConfig
}: Props) => {
  const {
    user
  } = useCurrentUser();
  const [config, setConfig] = useState(null);
  const [enable, setEnable] = useState(!rootConfig);
  const {
    data,
    refetch
  } = useQuery({
    url: "/config",
    enabled: !!enable
  });
  useEffect(() => {
    const newConfig = data || rootConfig;
    const flashSaleActive = newConfig?.flash_sale?.to && new Date().getTime() < new Date(newConfig?.flash_sale?.to).getTime();
    if (newConfig) {
      setConfig({
        ...newConfig,
        on_sale: newConfig?.on_sale || flashSaleActive,
        flash_sale: newConfig.flash_sale ? {
          ...newConfig.flash_sale,
          color: '#D8042C',
          isActive: flashSaleActive,
          isNotStarted: new Date().getTime() < new Date(newConfig.flash_sale.from).getTime(),
          isGoingOn: new Date().getTime() > new Date(newConfig.flash_sale.from).getTime() && new Date().getTime() < new Date(newConfig.flash_sale.to).getTime()
        } : null
      });
    }
  }, [data, rootConfig]);
  const handleRefetch = useCallback(() => {
    setEnable(true);
    setTimeout(() => {
      refetch();
    }, 0);
  }, []);
  return <LayoutContext.Provider value={{
    config,
    user,
    refetch: handleRefetch
  }} data-sentry-element="unknown" data-sentry-component="LayoutProvider" data-sentry-source-file="provider.tsx">
      {children}
    </LayoutContext.Provider>;
};
export function useLayoutContext() {
  return useContext(LayoutContext);
}