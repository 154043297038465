"use client";

import React, { memo, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NextImage, NextLink, CancelButton, ConfirmButton, Icons } from "@src/components";
import { formatPrice } from "@src/utils/common";
import { ROUTES } from "@src/constants/routes";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProductModel from "@src/models/product";
import { useResponsive } from "@src/hooks";
import Link from "next/link";
import { useLayoutContext } from "@src/layouts/provider";
const ProductCard = memo((props: {
  product: ProductModel;
  onSelectedProduct: (product: ProductModel) => void;
  isLiked: boolean;
  toggleLike: (isLiked: boolean) => () => void;
}) => {
  const {
    product,
    onSelectedProduct,
    isLiked,
    toggleLike
  } = props;
  const {
    config
  } = useLayoutContext();
  const {
    flash_sale
  } = config || {};
  const {
    name,
    promotion_price: discountPrice,
    sale_price,
    is_new,
    discount_percent,
    image: productImage,
    images,
    slug,
    out_of_stock,
    is_best_seller,
    original_price: price
  } = product || {};
  const {
    isMobile
  } = useResponsive();
  const [currentSubImage, setCurrentSubImage] = useState(null);
  const [subImages, setSubImages] = useState([]);
  const iconRef = useRef(null);
  const handleSubImage = (_, value) => {
    setCurrentSubImage(value);
  };
  const handleMouseEnter = () => {
    if (!iconRef.current || isMobile) return;
    iconRef.current.style.opacity = 1;
  };
  const handleMouseLeave = () => {
    if (!iconRef.current || isMobile) return;
    iconRef.current.style.opacity = 0;
  };
  const handleBuyNow = () => {
    onSelectedProduct?.(product);
  };
  const handleAddToCart = () => {
    onSelectedProduct?.(product);
  };
  useEffect(() => {
    if (product) {
      const uniqueImages = images.filter((image, index, self) => index === self.findIndex(obj => obj.small === image.small && obj.large === image.large));
      setSubImages(uniqueImages);
    }
  }, [product]);
  const renderType = () => {
    if (out_of_stock) {
      return <Box sx={{
        borderRadius: "4px",
        padding: "4px",
        backgroundColor: "#F46647"
      }}>
            <Typography sx={{
          fontSize: "10px",
          color: "#FFF",
          fontWeight: 700
        }}>
              Hết hàng
            </Typography>
          </Box>;
    }
    if (product.promotion_price > 0) {
      return <Box sx={{
        color: "#FFF",
        fontWeight: 700,
        backgroundColor: flash_sale?.isActive ? flash_sale?.color : "#F46647",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "52px",
        height: "24px",
        borderRadius: "4px"
      }}>
            <span style={{
          fontSize: "16px"
        }}>
              {Number(discount_percent).toFixed(0)}%
            </span>
          </Box>;
    }
    if (is_best_seller) {
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#8DC960" />
            <path d="M13.9106 14.076C13.9106 13.6707 13.7772 13.3613 13.5106 13.148C13.2546 12.924 12.8972 12.812 12.4386 12.812H10.4546V15.292H12.4706C12.9292 15.292 13.2812 15.1907 13.5266 14.988C13.7826 14.7853 13.9106 14.4813 13.9106 14.076ZM13.6386 9.644C13.6386 9.26 13.5159 8.96667 13.2706 8.764C13.0359 8.56133 12.6946 8.46 12.2466 8.46H10.4546V10.812H12.2466C12.6946 10.812 13.0359 10.716 13.2706 10.524C13.5159 10.3213 13.6386 10.028 13.6386 9.644ZM16.6786 14.444C16.6786 15.3827 16.3532 16.1293 15.7026 16.684C15.0626 17.228 14.1452 17.5 12.9506 17.5H7.71856V6.268H12.7746C13.9266 6.268 14.8226 6.52933 15.4626 7.052C16.1026 7.57467 16.4226 8.284 16.4226 9.18C16.4226 9.86267 16.2412 10.4227 15.8786 10.86C15.5159 11.2867 15.0626 11.58 14.5186 11.74C14.8492 11.804 15.1479 11.9213 15.4146 12.092C15.6812 12.2627 15.9052 12.4707 16.0866 12.716C16.2786 12.9507 16.4226 13.2173 16.5186 13.516C16.6252 13.8147 16.6786 14.124 16.6786 14.444Z" fill="white" />
          </svg>;
    }
    if (is_new) {
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#85A1FC" />
            <path d="M17.0244 6.268V17.5H14.2884L9.71238 10.572V17.5H6.97638V6.268H9.71238L14.2884 13.228V6.268H17.0244Z" fill="white" />
          </svg>;
    }
    return null;
  };
  return <Box sx={{
    backgroundColor: "#F3F3F3",
    borderRadius: "12px",
    padding: {
      xs: "6px",
      md: "16px"
    },
    position: "relative"
  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Box sx={{
      position: "absolute",
      top: {
        xs: 6,
        md: 16
      },
      left: {
        xs: 6,
        md: 16
      },
      zIndex: 1
    }}>
          {renderType()}
        </Box>
        <Box sx={{
      position: "absolute",
      top: 20,
      right: 20,
      zIndex: 1,
      background: "white",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      cursor: "pointer",
      opacity: {
        xs: 1,
        md: 0
      },
      transition: "opacity 0.3s"
    }} ref={iconRef}>
          <Icons sx={{
        ...(isLiked && {
          "& svg ": {
            fill: "#FC5E5E",
            color: "#FC5E5E"
          }
        })
      }} name="heart" type="button" onClick={toggleLike(!isLiked)} />
        </Box>
        <Link href={`${ROUTES.PRODUCT}/${slug}`}>
          <NextImage className="zoom-in flashing" src={subImages?.[currentSubImage]?.large || productImage || ""} sx={{
        borderRadius: "8px",
        aspectRatio: "4/5",
        minHeight: "200px",
        "& img": {
          borderRadius: "8px",
          objectFit: "cover",
          cursor: "pointer"
        },
        width: "100%",
        cursor: "pointer"
      }} alt={name} />
        </Link>
        <Stack mt={2} direction="column" spacing={1.5}>
          <NextLink sx={{
        "& a:hover": {
          color: "#F46647"
        }
      }} className="truncate-1" variant="body1" href={`${ROUTES.PRODUCT}/${slug}`}>
            {name}
          </NextLink>
          {discountPrice ? <Stack justifyItems="center" spacing={2}>
              <Typography variant="body1" component="span" sx={{
          fontSize: {
            xs: 14,
            md: 16
          },
          fontWeight: 700,
          color: flash_sale?.isActive ? flash_sale?.color : "#000"
        }}>
                {formatPrice(discountPrice)}
              </Typography>
              <Typography variant="body1" component="span" sx={{
          fontSize: {
            xs: 14,
            md: 16
          },
          fontWeight: 600,
          opacity: 0.5,
          textDecoration: "line-through"
        }}>
                {formatPrice(price)}
              </Typography>
            </Stack> : <Stack justifyItems="center" spacing={2}>
              <Typography variant="body1" component="span" sx={{
          fontSize: {
            xs: 14,
            md: 16
          },
          fontWeight: 700
        }}>
                {formatPrice(price)}
              </Typography>
            </Stack>}

          <Stack overflow="auto" spacing={2}>
            <Tabs value={currentSubImage} onChange={handleSubImage} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example" sx={{
          position: "relative",
          "& .MuiTabs-indicator": {
            display: "none"
          },
          "& .MuiTabScrollButton-root": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "20px",
            height: "20px",
            zIndex: 99,
            opacity: 1,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&.Mui-disabled": {
              opacity: 0,
              display: "none"
            }
          },
          "& .MuiTabScrollButton-root:nth-child(1)": {
            left: 0
          },
          "& .MuiTabScrollButton-root:nth-child(4)": {
            right: 0
          }
        }}>
              {subImages.map((subImage, index) => <Tab sx={{
            minWidth: "40px",
            height: "40px",
            borderRadius: "8px",
            cursor: "pointer",
            flexShrink: 0,
            padding: 0,
            marginRight: 1.5,
            ...(index === currentSubImage && {
              border: "1px solid #000"
            })
          }} label={<NextImage key={index} src={subImage?.small} sx={{
            width: "100%",
            height: "100%",
            "& img": {
              objectFit: "cover",
              borderRadius: "8px"
            }
          }} />}></Tab>)}
            </Tabs>
          </Stack>
          <Stack sx={{
        paddingTop: 0.5
      }} direction="column" spacing={1}>
            <ConfirmButton sx={{
          "& span": {
            fontSize: "12px"
          }
        }} height={40} type="button" label="Mua ngay" onClick={handleBuyNow} />
            <CancelButton sx={{
          "& span": {
            fontSize: "12px"
          }
        }} height={40} type="button" label="Thêm vào giỏ" onClick={handleAddToCart} />
          </Stack>
        </Stack>
      </Box>;
});
export default ProductCard;